import './Footer.css';
import { Link } from 'react-router-dom';
import Appointments from './pages/appointments';
import Our_Team from './pages/our_team';
import TMJ_info from './pages/tmj_info';


function Footer () {
    return (
        <>
        <div className='make-app-footer'>
            <h1 id='make-app-footer-title' data-aos="fade-in">Schedule Your Appointment Today</h1>
            <div className='button-nav1' data-aos="fade-in">
            <a href="https://app.nexhealth.com/appt/tmj-head-and-neck-pain-center" element={<Appointments/>} id='button-nav1' data-aos="fade-in">Get Started</a>
            </div>
        </div>
        <div className='footer'>
            <div>
                <div id='bottom' data-aos="fade-in">
                    <div className='logo-name1'>
                        <Link to='/'><h1 id='logo-name4'>TMJ Head & Neck Pain Center</h1></Link>
                        <h3 id='logo-subtitle3'>also treating sleep apnea</h3>
                    </div>
                    <br />
                </div>
                <div className='footer-hours-time2' data-aos="fade-in">
                    <a>Office: 760-770-4033</a>
                    <a>Fax: 760-770-3975</a>
                </div>
                <div className='google-location' data-aos="fade-in">
                    <a id='bottom-font' href='https://www.google.com/maps/place/TMJ+Head+%26+Neck+Pain+Center/@33.7895759,-116.4075622,17z/data=!3m1!4b1!4m6!3m5!1s0x80dafd430cff43ff:0xe695f17553c469da!8m2!3d33.7895759!4d-116.4053735!16s%2Fg%2F1tjv3t8v?entry=ttu'><h5>35900 Bob Hope Dr., Suite 110</h5></a>
                    <a id='bottom-font' href='https://www.google.com/maps/place/TMJ+Head+%26+Neck+Pain+Center/@33.7895759,-116.4075622,17z/data=!3m1!4b1!4m6!3m5!1s0x80dafd430cff43ff:0xe695f17553c469da!8m2!3d33.7895759!4d-116.4053735!16s%2Fg%2F1tjv3t8v?entry=ttu'><h5>Rancho Mirage, CA 92270</h5></a>
                </div>
                <br></br>
                <div className='footer-hours' data-aos="fade-in">
                    <div className='footer-hours-time'>
                        <div>
                            <h5 id='footer-day'>Monday:</h5>
                        </div>
                        <div>
                            <h5>8:00 AM - 5:00 PM</h5>
                        </div>
                    </div>
                    <div className='footer-hours-time'>
                        <div>
                            <h5 id='footer-day'>Tuesday:</h5>
                        </div>
                        <div>
                            <h5>8:00 AM - 5:00 PM</h5>
                        </div>
                    </div>
                    <div className='footer-hours-time'>
                        <div>
                            <h5 id='footer-day'>Wednesday:</h5>
                        </div>
                        <div>
                            <h5>By Appointment</h5>
                        </div>
                    </div>
                    <div className='footer-hours-time'>
                        <div>
                            <h5 id='footer-day'>Thursday:</h5>
                        </div>
                        <div>
                            <h5>8:00 AM - 5:00 PM</h5>
                        </div>
                    </div>
                    <div className='footer-hours-time'>
                        <div>
                            <h5 id='footer-day'>Friday:</h5>
                        </div>
                        <div>
                            <h5>Closed</h5>
                        </div>
                    </div>

                </div>


            </div>
            <div className='footer-contact'>
            </div>
        </div></>
    ); 
}

export default Footer;